import PropTypes from "prop-types";
import React from "react";

import CardListView from "./CardListView";
import styles from "./ClubList.module.scss";

const returnFeaturedAmenities = (featuredAmenities) => {
  if (featuredAmenities) {
    if (typeof featuredAmenities[0] === "string") {
      return featuredAmenities;
    }

    return featuredAmenities.reduce((amenities, amenity) => {
      if (amenity.fields) {
        return amenities.concat(amenity.fields.amenityName);
      }
    }, []);
  }
  return featuredAmenities;
};

const ClubList = ({
  clubs = [],
  focusClubId,
  getHoveredClub,
  hideCTA = false,
  onSelect,
  showMap,
  showThumbnail = true,
}) => {
  const setHoverClub = (e, id) => {
    getHoveredClub(id);
  };

  return (
    <div className={styles.clubList} data-is="ClubList">
      {clubs
        ?.filter((fa) => fa !== undefined)
        .map((club) => {
          const {
            clubLabel,
            facilityId,
            webName,
            address,
            city,
            state,
            zip,
            phoneNumber: phone,
            clubDetailPageURL,
            featuredAmenities,
          } = club;

          const thumbnailImgUrl = club?.thumbnail?.fields?.file?.url || "";

          return (
            <CardListView
              clubLabel={clubLabel}
              cta={hideCTA ? null : { linkCopy: "View this club" }}
              featuredAmenities={returnFeaturedAmenities(featuredAmenities)}
              focusClubId={focusClubId}
              headline={webName}
              id={facilityId}
              imgUrl={showThumbnail ? thumbnailImgUrl : null}
              key={facilityId}
              link={clubDetailPageURL}
              onClick={onSelect && (() => onSelect(club))}
              setHoverClub={setHoverClub}
              showMap={showMap}
              subcopy={{ address, city, phone, state, zip }}
              url={`${clubDetailPageURL}`}
            />
          );
        })}
    </div>
  );
};

ClubList.propTypes = {
  clubs: PropTypes.array,
  focusClubId: PropTypes.number,
  getHoveredClub: PropTypes.func,
  hideCTA: PropTypes.bool,
  onSelect: PropTypes.func,
  showMap: PropTypes.bool,
  showThumbnail: PropTypes.bool,
};

export default ClubList;
