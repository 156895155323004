import PropTypes from "prop-types";
import React from "react";

import ChangeClubPanel from "@/components/ChangeClubPanel";
import addCurrencySymbol from "@/utils/helpers/addCurrencySymbol";

import ChangeClubButton from "../ChangeClubButton";
import styles from "./MembershipPlans.module.scss";

const MembershipPlans = ({
  handleClubChange,
  openPanel,
  plans,
  replaceDynamicContentOfPlan,
  selectedClubDetails,
  selectedPlanId,
  updatePlanDetails,
}) => {
  const onPlanClick = (event, plan) => {
    event.stopPropagation();
    updatePlanDetails({ selectedPlan: plan });
  };

  if (!plans || plans.length === 0) {
    return (
      <div className={styles.membershipPlans}>
        <div className={styles.noPlans}>
          <ChangeClubButton
            className={styles.changeClubButton}
            handleClick={() =>
              openPanel(
                <ChangeClubPanel
                  joinFlow={true}
                  onChangeClub={handleClubChange}
                />
              )
            }
          >
            Select a club to continue
          </ChangeClubButton>
        </div>
      </div>
    );
  }

  return (
    <ul className={styles.membershipPlans} data-is="MembershipPlans">
      {plans &&
        plans.length > 0 &&
        plans.map((plan, index) => {
          const {
            cost: planCost,
            planId,
            name: planName,
            planType = "",
            description: planDescription,
          } = plan;

          return (
            <li
              aria-checked={selectedPlanId === planId}
              className={styles.plan}
              id={`plan-${planId}`}
              key={planId}
              onClick={(e) => onPlanClick(e, plan)}
              role="menuitemcheckbox"
            >
              <small className={styles.planType}>{planType}</small>

              <label className={styles.planName}>
                <input
                  checked={selectedPlanId === planId}
                  data-adobe={planName}
                  id={planId}
                  name="plan"
                  onChange={(e) => onPlanClick(e, plan)}
                  type="radio"
                  value={planId}
                />
                {replaceDynamicContentOfPlan(planDescription)}
              </label>

              {index === 0 && (
                <ChangeClubButton
                  className={styles.changeClubButton}
                  handleClick={() =>
                    openPanel(
                      <ChangeClubPanel
                        joinFlow={true}
                        onChangeClub={handleClubChange}
                      />
                    )
                  }
                />
              )}

              <div className={styles.planCost}>
                <strong className={styles.planCostAmount}>
                  {addCurrencySymbol(
                    planCost.monthlyFee,
                    selectedClubDetails.country
                  )}
                </strong>
                <span className={styles.planCostTerm}>Monthly</span>
              </div>

              <small className={styles.commitmentTerm}>12-mo commitment</small>
            </li>
          );
        })}
    </ul>
  );
};

MembershipPlans.propTypes = {
  handleClubChange: PropTypes.func,
  openPanel: PropTypes.func.isRequired,
  plans: PropTypes.array,
  replaceDynamicContentOfPlan: PropTypes.func.isRequired,
  selectedClubDetails: PropTypes.object,
  selectedPlanId: PropTypes.number,
  updatePlanDetails: PropTypes.func.isRequired,
};

export default MembershipPlans;
