export const getUserLocation = () => {
  return new Promise((resolve) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            errorCode: null,
            hasLocationAccess: true,
            location: {
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            },
            success: true,
          });
        },
        () => {
          resolve({
            errorCode: "ACCESS_DENIED",
            hasLocationAccess: false,
            location: null,
            success: false,
          });
        },
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      resolve({
        errorCode: "GEOLOCATION_API_NOT_SUPPORTED",
        hasLocationAccess: false,
        location: null,
        success: false,
      });
    }
  });
};
