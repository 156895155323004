import { v4 as uuidv4 } from "uuid";

import Utilities from "@/api/equifit/utilities";
import { ACDL_LEAD_FLOW_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";
import { COUNTRIES } from "@/constants";
import { LeadFlowConfig } from "@/features/lead-flow";
import { createItem, getItem, removeItem } from "@/utils/helpers/localStorage";

export const LEAD_FLOW_LS_DATA_KEY = "leadFlowData";
export const WorkErrorMessage = "We don’t recognize that company email.";

/**
 *
 */
export const derivePayloadFromState = (leadFlowState) => {
  const facility =
    leadFlowState.scheduledFacility ||
    leadFlowState.selectedFacility ||
    leadFlowState.suggestedFacility;

  const referrerDetails = leadFlowState?.referrerDetails;
  const companyEmail =
    leadFlowState?.contactInput?.["Company Email (optional)"] || "";
  const zipCode = leadFlowState?.contactInput?.["Zip Code (optional)"] || "";

  const payload = {
    answers: leadFlowState?.personalizeOptions || [],
    ...(companyEmail && { companyEmail }),
    ...(zipCode && { zipCode }),
    email: leadFlowState?.contactInput?.["Email Address"] || "",
    facilityId: facility?.facilityId || "",
    firstName: leadFlowState?.contactInput?.["First Name"] || "",
    isAbandonedLead: true,
    isCorpLead: leadFlowState?.isCorpLead,
    joinOutreachCode: leadFlowState?.joinOutreachCode || "",
    lastName: leadFlowState?.contactInput?.["Last Name"] || "",
    leadTokenId: leadFlowState?.leadTokenId || "",
    leadTransactionId: leadFlowState?.leadTransactionId || uuidv4(),
    meetingPurpose: leadFlowState.meetingPurpose,
    ...(referrerDetails?.memberId && { memberId: referrerDetails?.memberId }),
    newsletter:
      facility?.country !== COUNTRIES.UNITED_KINGDOM.abbreviation ||
      leadFlowState?.subscribeToNewsletter,
    outreachCode: leadFlowState?.outreachCode || "",
    phone: leadFlowState?.contactInput?.["Phone Number"] || "",
    receiveTextAlerts: leadFlowState?.receiveTextAlerts,
    ...(referrerDetails?.referringId && {
      referringId: referrerDetails?.referringId,
    }),
    scheduledVisitTime: leadFlowState?.scheduledVisitTime || "",
    ...(referrerDetails?.shareId && { shareId: referrerDetails?.shareId }),
  };

  return payload;
};

/**
 *
 */
export const updateStoredState = (leadFlowState) => {
  if (Utilities.isLocalStorageAvailable()) {
    createItem(LEAD_FLOW_LS_DATA_KEY, leadFlowState);
  }
};

/**
 *
 */
export const clearStoredState = () => {
  if (Utilities.isLocalStorageAvailable()) {
    removeItem(LEAD_FLOW_LS_DATA_KEY);
  }
};

/**
 *
 */
export const deriveEmailPayloadFromState = (formState) => {
  const facility =
    formState.scheduledFacility ||
    formState.selectedFacility ||
    formState.suggestedFacility;

  const payload = {
    corporateEmail: formState.contactInput["Company Email"],
    facilityId: facility.facilityId,
    firstName: formState.contactInput["First Name"],
    lastName: formState.contactInput["Last Name"],
    personalEmail: formState.contactInput["Personal Email"],
    phoneNumber: formState.contactInput["Phone Number"],
  };
  return payload;
};

/**
 *
 */
export const deriveCorpPayloadFromState = (formState) => {
  const facility =
    formState.scheduledFacility ||
    formState.selectedFacility ||
    formState.suggestedFacility;

  const payload = {
    CTALabel: formState.ctaLabel,
    EmailAddress: formState.contactInput["Personal Email"],
    FacilityId: facility.facilityId,
    FirstName: formState.contactInput["First Name"],
    IsAbandonedLead: true,
    LastName: formState.contactInput["Last Name"],
    PhoneNumber: formState.contactInput["Phone Number"],
    SourceUrl: formState.sourceUrl,
    UserLocation: facility.facilityId,
    companyEmail: formState.contactInput["Company Email"],
  };
  return payload;
};

/**
 *
 */
export const getStoredState = () => {
  if (Utilities.isLocalStorageAvailable()) {
    const leadFlowState = getItem(LEAD_FLOW_LS_DATA_KEY);
    if (leadFlowState) {
      const { createdDate } = leadFlowState;
      const now = new Date().getTime();
      const msBetweenDates = Math.abs(createdDate - now);
      const hasBeenFiftenMinutes = msBetweenDates > 900000; // 15 minutes
      if (hasBeenFiftenMinutes) {
        // If the data is older than 15 minutes, clear it
        clearStoredState();
        return null;
      }
      return leadFlowState;
    }
  }
};

/**
 *
 */
export const collateForAdobeDataLayer = (
  personalizaionAnswersArr,
  step,
  trackingName,
  trackerLength = []
) => {
  const collatedAnswers = [];

  personalizaionAnswersArr.forEach((personalAnswer) => {
    const possibleAnswersForStep =
      LeadFlowConfig.personalizeQuiz[step]?.content || [];
    const answerIsInStep = !!possibleAnswersForStep.find(
      (answer) => answer.name === personalAnswer.name
    );

    if (answerIsInStep) {
      collatedAnswers.push(
        `${trackingName?.split("-")[1]} | ${personalAnswer.name?.toLowerCase()}`
      );
    }
  });

  // check tracker of previously answered questions
  if (collatedAnswers.length > 0 && trackerLength.length > 0) {
    const trackerLen = trackerLength[trackerLength.length - 1];
    const collatedLen = collatedAnswers.length;
    if (trackerLen > collatedLen) {
      // push remaining undefined to avoid patching extra answers to next layer
      for (let i = 0; i < trackerLen - collatedLen; i++) {
        collatedAnswers.push(undefined);
      }
    }
  }

  return collatedAnswers;
};

/**
 * ACDL Logic
 */
export const handleAdobeTracking = async (club, setNewDataLayer) => {
  const { city, clubId, clubType, facilityId, name, status } = club;

  const ACDLClubSelectionInfo = ACDL_LEAD_FLOW_TRACKING.leadFlowClubSelection(
    name.toLowerCase(),
    facilityId,
    city.toLowerCase(),
    status,
    clubId,
    clubType
  );

  setNewDataLayer(ACDLClubSelectionInfo);
};

/**
 *
 */
export const isClubAcceptingAppointments = (club, config) => {
  if (!config || !club) {
    return false;
  }
  const clubsForAppointment =
    config.clubsForAppointment || config.fields.clubsForAppointment;
  const { facilityId } = club;

  if (!clubsForAppointment) {
    return false;
  }

  const isAcceptingAppointments = clubsForAppointment.some((clubFromConfg) => {
    const clubFromConfigId =
      clubFromConfg.facilityId || clubFromConfg.fields.facilityId;

    return facilityId === clubFromConfigId;
  });

  return !!isAcceptingAppointments;
};
