import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";

import Loader from "@/components/Loader";
import ResponsiveImage from "@/components/ResponsiveImage";
import ResponsiveVideo from "@/components/ResponsiveVideo";
import useAcdl from "@/hooks/useAcdl";

import styles from "./ClubGallery.module.scss";

const ClubGallery = ({ clubName, slides }) => {
  let sliderRef = useRef(null);

  const [currentSlide, setCurrentSlide] = useState({ index: 0, slide: {} });
  const { acdlPush } = useAcdl();

  useEffect(() => {
    if (slides?.length) {
      setCurrentSlide({ index: 0, slide: slides[0] });
    }
  }, [slides]);

  const nextSlide = () => {
    const isVideo = currentSlide.slide.fields?.mediaType === "Video";
    acdlPush({
      click: {
        destination: "NA",
        location: `${clubName} ${currentSlide.slide.fields?.title} | Carousel`,
        name: "Right Icon",
      },
      event: "mod-click",
      module: {
        name: "CarouselTapped",
        position: `${currentSlide.index + 1}/${slides.length}`,
        type: isVideo ? "video" : "image",
      },
    });
    sliderRef.slickNext();
  };

  const previousSlide = () => {
    const isVideo = currentSlide.slide.fields?.mediaType === "Video";
    acdlPush({
      click: {
        destination: "NA",
        location: `${clubName} ${currentSlide.slide.fields?.title} | Carousel`,
        name: "Left Icon",
      },
      event: "mod-click",
      module: {
        name: "CarouselTapped",
        position: `${currentSlide.index + 1}/${slides.length}`,
        type: isVideo ? "video" : "image",
      },
    });
    sliderRef.slickPrev();
  };

  const settings = {
    arrows: false,
    beforeChange: (_, index) =>
      setCurrentSlide({ index, slide: slides[index] }),
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  };

  if (slides === null) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="dark" />
      </div>
    );
  }

  if (slides?.length === 0) {
    return null;
  }

  return (
    <div className={styles.galleryContainer}>
      <Slider
        ref={(slider) => {
          sliderRef = slider;
        }}
        {...settings}
      >
        {slides.map((slide) => {
          const slideIsVideo = slide.fields.mediaType === "Video";
          const { title, media, mediaMobile } = slide.fields;
          const slideMedia = slideIsVideo ? (
            <ResponsiveVideo
              defaultVideo={mediaMobile}
              lgVideo={media}
              title={title}
            />
          ) : (
            <ResponsiveImage
              altText={title}
              defaultImage={mediaMobile}
              lgImage={media}
            />
          );
          return (
            <div className={styles.slideMedia} key={slide.sys.id}>
              {slideMedia}
            </div>
          );
        })}
      </Slider>
      <div className={styles.slideMediaDetails}>
        <div>
          {currentSlide.index + 1} / {slides.length}
        </div>
        <div className={styles.slideMediaTitle}>
          {`${clubName} ${currentSlide.slide.fields?.title}`}
        </div>
        <button
          className={styles.slideControlButton}
          disabled={currentSlide.index === 0}
          onClick={previousSlide}
        >
          <IoIosArrowBack />
        </button>
        <button
          className={styles.slideControlButton}
          disabled={currentSlide.index === slides.length - 1}
          onClick={nextSlide}
        >
          <IoIosArrowForward />
        </button>
      </div>
    </div>
  );
};

ClubGallery.propTypes = {
  clubName: PropTypes.string.isRequired,
  slides: PropTypes.array,
};

export default ClubGallery;
