import logger from "../../../utils/logger";
import ApiClient from "../client";

const ProductToken = {
  namespace: "productToken",

  basePath: "/v6/acq/payment/token",

  async generateAccountBalanceProductToken(data) {
    const endpoint = `${this.basePath}`;
    return ApiClient._post(endpoint, {
      baseURL: ApiClient.config().equinox_api.host,
      data,
    }).then((resp) => {
      if (!resp.data.success) {
        logger.warn(`API|Product Token: Could not generate productToken`);
        return null;
      }

      return resp.data.result;
    });
  },

  async generateProductToken(data) {
    const endpoint = `${this.basePath}`;
    return ApiClient._post(endpoint, {
      baseURL: ApiClient.config().equinox_api.host,
      data,
    }).then((resp) => {
      if (!resp.data.success) {
        logger.warn(`API|Product Token: Could not generate productToken`);
        return null;
      }

      return resp.data.result;
    });
  },
  async getProductDetails(token) {
    const endpoint = `${this.basePath}/${token}`;
    return ApiClient._get(endpoint, {
      baseURL: ApiClient.config().equinox_api.host,
    }).then((resp) => {
      if (!resp.data) {
        logger.warn(
          `API|Product Token: Could not find product details for token: ${token}`
        );
        return null;
      }

      return resp.data;
    });
  },
};

export default ProductToken;
