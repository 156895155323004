import MembershipPlansApiClient from "@/api/client/entities/corpMembership-plans";

const getFilteredPlans = async (facilityId, corpInfo, formerMemberId) => {
  const filteredPlans = [];
  let region;
  let promotionId;
  let memberId;
  let isPresale;
  let facilityStatus;
  let facilityOpenDate;
  let corporateAgreementId;

  if (facilityId) {
    // Get membership plans data from contentful which contains 3 titles and 3 descriptions for ALL plans
    // to show when plan is displayed in option1, option2, split respectively.
    let contentfulPlans;
    let apiPlans;

    // Fetch plans for passed facilityID from API which contains price
    // Information is always sorted by price and should be displayed in same order on UI.
    // If the join flow is started as a former member pass formerMemberId as a extra param for promotions.
    try {
      const membershipPlanDetailsResponse = await fetch(
        `/api/cms/membership/plans`
      );
      contentfulPlans = await membershipPlanDetailsResponse?.json();

      const facilityMembershipPlansResponse = !corpInfo
        ? await fetch(
            `/api/cms/facilities/${facilityId}/membership/plans?fmid=${formerMemberId || ""}`
          )
        : await MembershipPlansApiClient.findCorporateMembershipPlansById(
            facilityId,
            corpInfo.companyEmail,
            corpInfo.corporateAccountId
          );

      const facilityMembershipPlans = !corpInfo
        ? await facilityMembershipPlansResponse?.json()
        : await facilityMembershipPlansResponse;

      // till upsell is not implemented filter out the destination exec plans
      apiPlans = facilityMembershipPlans.result.filter(({ id }) => id !== 6);
      region = facilityMembershipPlans.region;
      promotionId = facilityMembershipPlans.promotionId;
      memberId = facilityMembershipPlans.memberId;
      isPresale = facilityMembershipPlans.isPresale;
      facilityStatus = facilityMembershipPlans.facilityStatus;
      facilityOpenDate = facilityMembershipPlans.facilityOpenDate;
      corporateAgreementId = facilityMembershipPlans.corporateAgreementId;
    } catch (e) {
      console.error(
        `Unable to fetch plans for facilityId [${facilityId}]. ERROR: ${e}`
      );

      return {
        error: true,
        isAvailableOnline: true,
        plans: filteredPlans,
      };
    }

    // For every plan returned from API find corresponding plan in plans returned from contentful
    // (NOTE: contentful returns all the plans regardless of facility)
    // Then create a object for every plan with price returned from API for that plan title and description from contentful.
    apiPlans.map((plan) => {
      const contentfulPlanData = contentfulPlans.find(
        (contentfulPlan) => contentfulPlan.planId === plan.id
      );

      // find out which description and title should be used on UI according to plans position on UI.
      if (contentfulPlanData) {
        filteredPlans.push({
          cost: plan.planProperties,
          description: contentfulPlanData.description,
          id: plan.id,
          name: contentfulPlanData.name,
          planId: plan.id,
          planType: contentfulPlanData.planType,
          promotion: plan.promotion,
        });
      }
    });
  }

  return {
    corporateAgreementId,
    error: false,
    facilityOpenDate,
    facilityStatus,
    isAvailableOnline: filteredPlans.length > 0,
    isPresale,
    memberId,
    plans: filteredPlans,
    promotionId,
    region,
  };
};

export default getFilteredPlans;
