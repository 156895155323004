import { v4 as uuidv4 } from "uuid";

import {
  NEWSLETTER_DECLINE_ANSWER_ID,
  NEWSLETTER_SUBSCRIBE_ANSWER_ID,
} from "@/constants";
import { utils as userFlowUtils } from "@/features/user-flow";

import { popupLeadCode } from "../../../utils/constants";
import { getActualURL, getCookieValue } from "../../../utils/helpers/document";
import { getItem } from "../../../utils/helpers/localStorage";
import logger from "../../../utils/logger";
import ApiClient from "../client";
import { ECID_COOKIE_NAME } from "./join-lead";

const LeadGen = {
  namespace: "leads",

  /**
   *
   */
  createAbandonedLead({
    firstName,
    lastName,
    facilityId,
    email,
    phone,
    newsletter,
    isAbandonedLead,
    outreachCode,
    ecid = getCookieValue(ECID_COOKIE_NAME),
    sourceUrl = getActualURL(),
    companyEmail,
    zipCode,
    gclid = getItem("gclid"),
    receiveTextAlerts = false,
  }) {
    const endpoint = `/v6/acq/leads/abandoned`;
    const leadTransactionId = zipCode ? `${uuidv4()}_zip_${zipCode}` : uuidv4();
    const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate;

    return ApiClient._post(endpoint, {
      data: {
        Answers: [
          {
            additionalInfo: "",
            answerId: newsletter
              ? NEWSLETTER_SUBSCRIBE_ANSWER_ID
              : NEWSLETTER_DECLINE_ANSWER_ID,
          },
        ],
        Ecid: ecid,
        EmailAddress: email,
        FacilityId: facilityId,
        FirstName: firstName.replace(/’/g, "'"),
        IsAbandonedLead: isAbandonedLead,
        LastName: lastName.replace(/’/g, "'"),
        OutreachCode: userFlowUtils.modifyOutreachCodeForVwoCampaign(
          outreachCode || popupLeadCode
        ),
        PhoneNumber: phone,
        SourceUrl: sourceUrl,
        TransactionId: leadTransactionId,
        UserLocation: facilityId,
        ...(companyEmail && { companyEmail }),
        ...(isGclidValid && { googleAdWords: gclid.value }),
        receiveTextAlerts,
      },
    }).then((resp) => {
      if (
        resp.data === 0 ||
        (typeof resp.data === "object" && !resp.data.success)
      ) {
        logger.warn("API|Leads: lead was not properly created");
        throw new Error();
      } else {
        return {
          leadTokenId: typeof resp.data === "object" ? resp.data.tokenId : null,
          leadTransactionId,
        };
      }
    });
  },

  /**
   *
   */
  updateAbandonedLead({
    scheduledVisitTime,
    leadTransactionId,
    facilityId,
    meetingPurpose,
    newsletter,
    outreachCode,
    preferedCommunicationMethod,
    preferredTimeOfDay,
  }) {
    const endpoint = `/v6/acq/leads/abandoned`;
    return ApiClient._put(endpoint, {
      data: {
        Answers: [
          {
            additionalInfo: "",
            answerId: newsletter
              ? NEWSLETTER_SUBSCRIBE_ANSWER_ID
              : NEWSLETTER_DECLINE_ANSWER_ID,
          },
        ],
        FacilityId: facilityId,
        OutreachCode:
          userFlowUtils.modifyOutreachCodeForVwoCampaign(outreachCode),
        PreferredCommunicationMethod: preferedCommunicationMethod,
        PreferredTimeOfDay: preferredTimeOfDay,
        ScheduledVisitTime: scheduledVisitTime,
        TransactionId: leadTransactionId,
        meetingPurpose,
      },
    }).then((resp) => resp.data.tokenId);
  },

  /**
   *
   */
  updateLead({ answers, leadTokenId, facilityId, scheduledVisitTime }) {
    const endpoint = "/v6/acq/lead";
    return ApiClient._put(endpoint, {
      data: {
        TokenId: leadTokenId,
        ...(answers?.length > 0 && { Answers: [...answers] }),
        ...(facilityId && { FacilityId: facilityId }),
        ...(scheduledVisitTime && { ScheduledVisitTime: scheduledVisitTime }),
      },
    });
  },
};

export default LeadGen;
