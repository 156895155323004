import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import classNames from "classnames";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { ACDL_LEAD_FLOW_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";

import { personalizeQuiz } from "../config";
import { collateForAdobeDataLayer } from "../utils";
import styles from "./PersonalizeQuiz.module.scss";

const hasOption = (optionsArr, answer) =>
  !!optionsArr.find((option) => option.answerId === answer.id);

const renderQuizItems = (
  quizItems,
  clickHandler,
  currentOptionsArr,
  allowMultiple
) => {
  if (!quizItems) {
    return [];
  }

  const ButtonComponent = allowMultiple ? Checkbox : Radio;

  return (
    <div>
      {quizItems.map((quizItem) => {
        return (
          <div
            className={styles.quizItemContainer}
            data-context={quizItem.context}
            key={quizItem.name}
          >
            <FormControlLabel
              className={styles.quizItem}
              control={
                <ButtonComponent
                  checked={hasOption(currentOptionsArr, quizItem)}
                  className={styles.quizItemCheckbox}
                />
              }
              label={quizItem.name}
              labelPlacement="end"
              onClick={(e) => clickHandler(e, quizItem)}
            />
          </div>
        );
      })}
    </div>
  );
};

const PersonalizeQuiz = ({
  onCtaClick,
  onItemClick,
  personalizeOptions = [],
  personalizeOptionsSelectedPerStep = {},
  personalizeOptionsStep = 1,
  setNewDataLayer,
}) => {
  const lastQuestionIndex = personalizeQuiz.length - 1;

  const [collatedAnsLength, setCollatedAnsLength] = useState([]);

  let currentOptionsArr = [...personalizeOptions];

  const { heading, subText, content, ctaText, allowMultiple } =
    personalizeQuiz[personalizeOptionsStep];

  const router = useRouter();
  const ctaIsEnabled =
    personalizeOptionsSelectedPerStep[personalizeOptionsStep] > 0 ||
    personalizeOptionsStep === lastQuestionIndex; // Last Question

  /**
   *
   */
  const handleQuizItemClick = (e, option) => {
    const nodeName = e.target.nodeName;

    if (nodeName === "INPUT") {
      if (hasOption(currentOptionsArr, option)) {
        // Toggles option if already selected
        if (personalizeOptionsSelectedPerStep[personalizeOptionsStep] > 0) {
          personalizeOptionsSelectedPerStep[personalizeOptionsStep] -= 1;
        }

        currentOptionsArr = currentOptionsArr.filter(
          (item) => item.answerId !== option.id
        );
      } else {
        // Resets options if not multiple
        if (!allowMultiple) {
          currentOptionsArr = currentOptionsArr.filter((item) => {
            const itemIsQuestionGroup = !!content.find(
              (contentItem) => contentItem.id === item.answerId
            );
            return !itemIsQuestionGroup;
          });
        }

        if (
          allowMultiple &&
          personalizeOptionsSelectedPerStep[personalizeOptionsStep] > 0
        ) {
          personalizeOptionsSelectedPerStep[personalizeOptionsStep] += 1;
        } else {
          personalizeOptionsSelectedPerStep[personalizeOptionsStep] = 1;
        }

        currentOptionsArr.push({
          answerId: option.id,
          name: option.name,
        });
      }

      onItemClick([...currentOptionsArr], personalizeOptionsSelectedPerStep);
    }
  };

  /**
   *
   */
  const handleCtaClick = async () => {
    const collatedAnsRes = collateForAdobeDataLayer(
      personalizeOptions,
      personalizeOptionsStep,
      personalizeQuiz[personalizeOptionsStep].trackingName,
      collatedAnsLength
    );
    const currentCollated = collatedAnsLength;
    currentCollated.push(collatedAnsRes.length);
    setCollatedAnsLength(currentCollated);

    setNewDataLayer(
      ACDL_LEAD_FLOW_TRACKING.leadFlowSurvey(
        personalizeQuiz[personalizeOptionsStep].trackingName,
        collatedAnsRes
      )
    );

    if (personalizeOptionsStep < lastQuestionIndex) {
      if (personalizeQuiz[personalizeOptionsStep + 1].trackingName) {
        setNewDataLayer(
          ACDL_LEAD_FLOW_TRACKING.surveyView(
            router.asPath,
            personalizeQuiz[personalizeOptionsStep + 1].trackingName
          )
        );
      }

      onCtaClick(personalizeOptionsStep + 1);
    } else {
      // Go to members benefits page
      router.push("/memberbenefits");
    }
  };

  /**
   *
   */
  useEffect(() => {
    setNewDataLayer(
      ACDL_LEAD_FLOW_TRACKING.surveyView(
        router.asPath,
        personalizeQuiz[1].trackingName
      )
    );
  }, [router.asPath, setNewDataLayer]);

  return (
    <div
      aria-expanded={personalizeOptionsStep > 0}
      className={styles.personalizeVisit}
    >
      <h4 className={styles.headingText}>{heading}</h4>
      <p className={styles.subHeadingText}>
        {subText}
        {personalizeOptionsStep < lastQuestionIndex && (
          <span>{`${personalizeOptionsStep}/${lastQuestionIndex - 1}`}</span>
        )}
      </p>
      {renderQuizItems(
        content,
        handleQuizItemClick,
        currentOptionsArr,
        allowMultiple
      )}
      <div
        className={classNames({
          [styles.ctaButtonContainer]: personalizeOptionsStep !== 5,
        })}
      >
        <button
          className={styles.ctaButton}
          disabled={!ctaIsEnabled}
          name="LeadFlow:PersonalizeVisit:PersonalizeQuiz"
          onClick={handleCtaClick}
        >
          {ctaText}
        </button>
      </div>
    </div>
  );
};

PersonalizeQuiz.propTypes = {
  onCtaClick: PropTypes.func,
  onItemClick: PropTypes.func,
  personalizeOptions: PropTypes.array,
  personalizeOptionsSelectedPerStep: PropTypes.object,
  personalizeOptionsStep: PropTypes.number,
  setNewDataLayer: PropTypes.func,
};

export default PersonalizeQuiz;
