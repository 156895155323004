import { useEffect } from "react";

/***
 * https://github.com/adobe/adobe-client-data-layer/wiki
 */
const useAcdl = () => {
  useEffect(() => {
    window.adobeDataLayer = window.adobeDataLayer || [];
  }, []);

  const acdlPush = (...params) => {
    if (typeof window === "object" && window.adobeDataLayer) {
      window.adobeDataLayer.push(...params);

      if (process.env.NEXT_PUBLIC_ENVIRONMENT === "localhost") {
        console.info("ACDL push(),", ...params);
      }
    } else {
      console.error("acdlPush() called before initialization,", ...params);
    }
  };

  return {
    acdlPush,
  };
};

export default useAcdl;
