import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";

import JoinConfirmationEmailApiClient from "@/api/client/entities/join-confirmation-email";
import Selected from "@/assets/icons/radio-selected.svg";
import PrimaryCta from "@/components/buttons/primary-cta";
import SecondaryCta from "@/components/buttons/secondary-cta";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import { ExitFlowConfirmation, Headline } from "@/features/user-flow";
import dynamicContentReplacer from "@/utils/helpers/dynamic_content_replacer";
import ScreenDimensions from "@/utils/helpers/screen_dimensions";

import styles from "./Confirmation.module.scss";

const Confirmation = ({
  data,
  isDesktop,
  selectedClubDetails,
  selectedPlanId,
  membershipDetails: { memberID, buyerInfo, transactionNumber },
}) => {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { setNewDataLayer } = useContext(DigitalDataLayerContext);

  const imageUrl = isDesktop
    ? selectedClubDetails.confirmationPageClubImageDesktop
    : selectedClubDetails.confirmationPageClubImageMobileTablet;
  const {
    activationCta,
    activationSubcopy,
    activationText,
    allDetails,
    headlineComponent,
  } = data.fields;

  const RenderListItem = (node, children) => (
    <li className={`d-flex ${styles.checkMark}`}>
      <Selected />
      <span>{children}</span>
    </li>
  );

  const resendEmail = async () => {
    const { firstName, lastName, email } = buyerInfo;
    try {
      // pass memberID, fullname, facilityId, email passed in token after payment is done
      await JoinConfirmationEmailApiClient.sendConfirmationEmail(
        memberID,
        `${firstName} ${lastName}`,
        selectedClubDetails.facilityId,
        email
      );
      setSending(false);
      setSent(true);
      setHasError(false);
    } catch (e) {
      setSending(false);
      setSent(false);
      setHasError(true);
      console.error(`Error sending confirmation email. ERROR: ${e}`);
    }
  };

  const handleResendClick = () => {
    setNewDataLayer({
      click: {
        destination: "NA",
        location: "Resend | body",
        name: "Resend",
      },
      event: "mod-click",
      module: {
        name: "ResendTapped",
        position: "1:1",
        type: "text",
      },
    });
    setSending(true);
    resendEmail();
  };

  const replaceDynamicContent = (value) => {
    const replacerConfig = {
      "{{BARCODE}}": memberID,
      "{{CLUB_NAME}}":
        selectedPlanId === 15 ? selectedClubDetails.webName : "Equinox",
      "{{EMAIL}}": buyerInfo.email,
      "{{MEMBERID}}": memberID,
    };
    return dynamicContentReplacer(value, replacerConfig);
  };

  const documentToReactOptions = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: RenderListItem,
    },
    renderText: (text) => replaceDynamicContent(text),
  };

  return (
    <section className={styles.confirmation} data-adobe={transactionNumber}>
      <ExitFlowConfirmation skipConfirmation={true} />

      <Headline
        className={styles.headline}
        eyebrow="Step 3/3"
        finePrint={headlineComponent?.fields.finePrint}
        photo={headlineComponent?.fields.image}
        subcopy={headlineComponent?.fields.subcopy}
        title={replaceDynamicContent(headlineComponent?.fields.title)}
      />

      <article className={styles.information}>
        <div className="">
          {documentToReactComponents(allDetails, documentToReactOptions)}
        </div>
        <div className={styles.ctaWrapper}>
          <div>{activationSubcopy}</div>

          <PrimaryCta
            key="cta"
            label={activationCta.fields.text}
            onClick={() => {
              setNewDataLayer({
                click: {
                  destination: "Equinox+ app",
                  location: activationCta.fields.text + " | body",
                  name: activationCta.fields.text,
                },
                event: "mod-click",
                module: {
                  name: "GetStarted",
                  position: "1:1",
                  type: "text",
                },
              });
            }}
            style={styles.cta}
            type="submit"
            url={activationCta.fields.url}
          />
        </div>

        <hr />
        <div className={styles.subCopy}>
          {replaceDynamicContent(activationText)}
          <br />
          <br />
          Didn’t get it?{" "}
          {sending ? (
            <span className={styles.sendingText}>Sending...</span>
          ) : (
            <SecondaryCta label="Resend" onClick={handleResendClick} />
          )}
        </div>

        {sent && (
          <p className={styles.resentText}>
            We’ve resent your confirmation email, please check your inbox.
          </p>
        )}

        {hasError && (
          <p className={styles.errorText}>
            Unable to Resend the Confirmation Email. Please try again
          </p>
        )}

        <div className={styles.clubImage}>
          {imageUrl && <img alt="" src={imageUrl.fields.file.url} />}
        </div>
      </article>
    </section>
  );
};

Confirmation.propTypes = {
  data: PropTypes.object,
  isDesktop: PropTypes.bool,
  membershipDetails: PropTypes.object,
  selectedClubDetails: PropTypes.object,
  selectedPlanId: PropTypes.number,
};

export default ScreenDimensions(Confirmation);
