import React from "react";

// Used on contact info form
export const textContent = {
  optInText:
    "Sign up to get texts from Equinox about exclusive invites, promotions, and news.",
  termsText: (
    <>
      By checking this box, you agree to receive recurring automated promotional
      and personalized marketing text messages (e.g. promotions or cart
      reminders) from Equinox at the cell number used when signing up. Consent
      is not a condition of any purchase. Reply HELP for help and STOP to
      cancel. Msg frequency varies. Msg & data rates may apply. View
      <a
        href="https://attnl.tv/t/bFQ"
        rel="noopener noreferrer"
        target="_blank"
      >
        Terms
      </a>
      &nbsp;and
      <a
        href="https://attnl.tv/p/bFQ"
        rel="noopener noreferrer"
        target="_blank"
      >
        Privacy
      </a>
      .
    </>
  ),
  ukLegalSubText:
    "**Please note that, even if you elect not to receive marketing materials, by completing and submitting this form you are requesting that an Equinox Membership Advisor contact you regarding membership and are agreeing that a Membership Advisor may contact you for such purpose, including by providing with you with information on any current new member specials.",
  ukOptInCBText: "Want to receive exclusive invites, promotions, and news?",
  ukOptInText: (
    <>
      We&apos;d like to send you exclusive invites, promotions and the latest
      news from Equinox Fitness Holdings UK Limited. We will only send you
      marketing materials we think will be of interest to you and you can
      unsubscribe any time. If you want to receive marketing offers and
      materials from us, please tick the box.** Learn more about our practices,
      and your rights and choices, in our
      <a href="/privacy" rel="noopener noreferrer" target="_blank">
        Privacy Policy
      </a>
      .
    </>
  ),
};

// Options used in the personalization quiz / on confirmation screen
const personalizeInterestOptions = [
  {
    context: "control",
    id: "83",
    name: "Cardio Conditioning",
  },
  {
    context: "control",
    id: "84",
    name: "Athletic Training",
  },
  {
    context: "control",
    id: "85",
    name: "Specialty Classes",
  },
  {
    context: "control",
    id: "86",
    name: "Yoga",
  },
  {
    context: "both",
    id: "87",
    name: "Pilates",
  },
  {
    context: "control",
    id: "88",
    name: "Cycling",
  },
  {
    context: "control",
    id: "89",
    name: "Barre",
  },
  {
    context: "control",
    id: "90",
    name: "Amenities",
  },
  {
    context: "both",
    id: "91",
    name: "Personal Training",
  },
  {
    context: "control",
    id: "92",
    name: "Equipment",
  },
  {
    context: "both",
    id: "93",
    name: "Spa",
  },
  {
    context: "control",
    id: "94",
    name: "Other",
  },
  {
    context: "control",
    id: "125",
    name: "Dance",
  },
  {
    context: "control",
    id: "124",
    name: "Boxing and Martial Arts",
  },
  {
    context: "control",
    id: "126",
    name: "Running",
  },
  {
    context: "control",
    id: "127",
    name: "Active Regeneration",
  },
  {
    context: "control",
    id: "128",
    name: "Pool/Swimming",
  },
  {
    context: "variant",
    id: "129",
    name: "Strength floor",
  },
  {
    context: "variant",
    id: "130",
    name: "Cardio equipment",
  },
  {
    context: "variant",
    id: "131",
    name: "Signature Classes",
  },
  {
    context: "variant",
    id: "132",
    name: "EQX+ app",
  },
];

const personalizeExperienceOptions = [
  {
    id: "98",
    name: "Newcomer - I have no/little experience or need help creating a new routine",
  },
  {
    id: "99",
    name: "Familiar/Comfortable - I have a routine, but sometimes could use new suggestions",
  },
  {
    id: "100",
    name: "Expert - I know what I’m doing",
  },
];

const personalizeGoalOptions = [
  {
    id: "101",
    name: "Lose weight",
  },
  {
    id: "102",
    name: "Tone up",
  },
  {
    id: "103",
    name: "Get stronger",
  },
  {
    id: "104",
    name: "Gain muscle mass",
  },
];

const personalizeImpactOptions = [
  {
    id: "119",
    name: "The location",
  },
  {
    id: "120",
    name: "Amenities",
  },
  {
    id: "121",
    name: "The equipment",
  },
  {
    id: "122",
    name: "Special events",
  },
  {
    id: "123",
    name: "Price",
  },
];

export const personalizeQuiz = [
  {
    ctaText: "Personalize visit",
    heading: "You're almost done!",
    subText: "Tell us about yourself so we can personalize your visit for you.",
    trackingName: "survey-start",
  },
  {
    allowMultiple: true,
    content: personalizeInterestOptions,
    ctaText: "Next",
    heading: "Personalize your visit",
    subText: "I'm interested in...",
    trackingName: "survey-interest",
  },
  {
    allowMultiple: false,
    content: personalizeExperienceOptions,
    ctaText: "Next",
    heading: "Personalize your visit",
    subText: "How would you rate your fitness experience level today?",
    trackingName: "survey-experience",
  },
  {
    allowMultiple: true,
    content: personalizeGoalOptions,
    ctaText: "Next",
    heading: "Personalize your visit",
    subText: "What are your fitness goals?",
    trackingName: "survey-goals",
  },
  {
    allowMultiple: false,
    content: personalizeImpactOptions,
    ctaText: "Submit",
    heading: "Personalize your visit",
    subText: "What will most impact your decision to join?",
    trackingName: "survey-influence",
  },
  {
    ctaText: "Explore member benefits",
    heading: "Thanks for personalizing your visit!",
    subText:
      "We received your information and will be in touch shortly. In the meantime, explore the benefits we have to offer here at Equinox.",
    // trackingName: "survey-complete",
  },
];

const flattenFields = (data) => {
  const { fields } = data;
  return {
    ...fields,
  };
};

export const parseConfigFromContentful = (contentfulData) => {
  const { config = {} } = contentfulData;
  const { fields = {} } = config;
  const { blackoutDates, clubsForAppointment, ...baseConfig } = fields;

  if (!clubsForAppointment) {
    return;
  }

  const parsedClubsForAppointment = clubsForAppointment.map((club) =>
    flattenFields(club)
  );
  const parsedBlackoutDates = blackoutDates.map((date) => flattenFields(date));

  baseConfig.clubsForAppointment = parsedClubsForAppointment;
  baseConfig.blackoutDates = parsedBlackoutDates;
  contentfulData.config = baseConfig;

  return contentfulData;
};
